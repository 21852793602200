import { Link } from "react-router-dom";

function BackBar({ to, label }) {
  label = label ? label : "Back";
  return (
    <div style={styles.container}>
      <Link to={to}>🡐 {label}</Link>
    </div>
  );
}

const styles = {
  searchInput: {
    flex: 1,
    maxWidth: 800,
    margin: "auto",
  },
  container: {
    padding: 5,
    maxWidth: 1500,
    display: "flex",
    color: "#D3D3D3",
    backgroundColor: "#555",
    margin: "auto",
    textAlign: "center",
  }
};

export default BackBar;
