// Partially following https://ui.dev/react-router-protected-routes-authentication/
import { Fragment } from "react";
//import { Navigate, useLocation } from "react-router-dom";

import Login from "./Login";
import useUser from "./useUser";
import Spinner from "../spinner";

const styles = {
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "#D3D3D3",
  },
  box: {
    paddingTop: 20,
    color: "#D3D3D3",
    textAlign: "center",
    maxWidth: 400,
    width: "100%",
    height: 400,
    margin: "auto",
    backgroundColor: "#444",
  },
};

function RequireUserLogin({ login, userLoginError, userUpdating }) {
  return (
    <Fragment>
      <div style={styles.background}>
        <div style={styles.box}>
          <h1>PioSOLVER</h1>
          <p>Welcome to PioSOLVER<br/>the GTO poker-hand analyzer</p>
          <Login
            login={login}
            userLoginError={userLoginError}
            userUpdating={userUpdating}
          />
        </div>
      </div>
    </Fragment>
  );
}

const canAuthenticate = true;

function HideIfNoUser({ children }) {
  const { user, userError, userLoading } = useUser();

  if (!canAuthenticate) return children;

  if (userLoading) return null;
  if (userError) return null;
  return user != null && user.email != null ? children : null;
}

function RequireUser({ children }) {
  const { user, userError, userLoading, userUpdating, userLoginError, login } =
    useUser();
  //   const location = useLocation();

  if (!canAuthenticate) return children;

  if (userLoading) return <Spinner />;

  // User is not authenticated
  if (userError && userError.status === 401) {
    return (
      <RequireUserLogin
        login={login}
        userLoginError={userLoginError}
        userUpdating={userUpdating}
      />
    );
  }

  // return (user != null && user.email != null)
  //   ? children
  //   : <Navigate to="/login" replace state={location.pathname} />;

  // Just show the login page if not logged in
  return user != null && user.email != null ? (
    children
  ) : (
    <RequireUserLogin
      login={login}
      userLoginError={userLoginError}
      userUpdating={userUpdating}
    />
  );
}

export default RequireUser;
export { HideIfNoUser, useUser };
