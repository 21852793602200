
export default function LicensesScreen() {
  return (
    <div style={styles.container}>
      <iframe src="https://licenses.piofiles.com/"
        title="licenses" width="100%" height="100%"
        style={styles.iFrame} />
    </div>
  );
}

const styles = {
  container: {
    minHeight: 800,
  },
  iFrame: {
    minHeight: 800,

  }
};
