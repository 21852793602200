import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import useUser from "./auth/useUser";

function NavBar() {
  const { user, logout } = useUser();
  const navigate = useNavigate();

  return (
    <Fragment>
      <div style={styles.container}>
        <div style={styles.logo} className="no-select">
          <Link style={styles.logoText} to="/">
            <span style={styles.logoPio}>pio</span>
            <span style={styles.logoSolver}>solver</span>
          </Link>
        </div>
        <div style={{ float: "right", padding: 10 }}>
          <span style={{ color: "#D3D3D3" }}>
            {user ? user.email : "" }&nbsp;&nbsp;
          </span>
          {user && user.player_id ?
            <button style={{ backgroundColor: "#D3D3D3" }}
              onClick={() => { logout(); navigate("/"); }}>
              Logout</button> : ""}
        </div>
        <div style={{ clear: "both" }}></div>
      </div>
      <Nav isMod/>
    </Fragment>
  );
}

function Nav(active) {
  const { isModerator } = useUser();
  return (
    <div style={styles.nav.container}>
      <PlayerNav active={active} />
      { isModerator ? (
        <Fragment>
          <span>&nbsp;|&nbsp;</span>
          <ModNav />
        </Fragment>
      ) : null}
    </div>
  );
}

function PlayerNav(active) {
  return (
    <ul style={styles.nav.mod.ul}>
      {["Home", "Documentation", "Licenses"].map((i) =>
        <NavRow active={active} title={i} key={i} style={styles.nav.mod.li} />)}
    </ul>
  );
}

function ModNav(active) {
  return (
    <ul style={styles.nav.mod.ul}>
      {["Dashboard", "Tickets", "Orders", "Players"].map((i) =>
        <NavRow active={active} title={i} key={i} style={styles.nav.mod.li} />)}
    </ul>
  );
}

function NavRow({ title, active, style }) {
  const url = title === "Home" ? "/" : "/" + title.toLowerCase();
  return (
    <li style={style}>
      <Link to={url}>
        {title}
      </Link>
    </li>
  );
}

const styles = {
  logoPio: {
    color: "#D3D3D3",
  },
  logoSolver: {
    color: "#636e72",
  },
  logoText: {
    color: "white",
  },
  logo: {
    fontSize: 36,
    fontWeight: "bold",
    float: "left",
    padding: 10,
    paddingTop: 0,
    margin: 0,
    color: "white",
  },
  container: {
    color: "#D3D3D3",
    backgroundColor: "#303952",
  },
  nav: {
    container: {
      padding: 5,
      background: "white",
      fontSize: 14,
    },
    player: {
      ul: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: -50,
        paddingLeft: 20,
        display: "inline",
      },
      li: {
        display: "inline",
        paddingLeft: 10,
        marginLeft: 0,
      },
    },
    mod: {
      ul: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: -45,
        display: "inline",
      },
      li: {
        display: "inline",
        paddingLeft: 10,
        marginLeft: 0,
      },
    },
  },
};
export default NavBar;
