import React from "react";
import Spinner from "../spinner";

function Login({ login, userLoginError, userUpdating }) {
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login({ email, password });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={styles.warning}>
        {userUpdating ? (
          <Spinner size={32} variant="light" message="Logging in..." />
        ) : ""}
        {userLoginError ? "Incorrect username and/or password." : null}
      </div>
      <div>
        <input
          autoFocus={true}
          required={true}
          type="email"
          placeholder="Email address"
          readOnly={userUpdating}
          onChange={(e) => setEmail(e.target.value.trim())}
        />
      </div>
      <div>
        <input
          required={true}
          style={{ borderTop: "1px solid black" }}
          type="password"
          placeholder="Password"
          readOnly={userUpdating}
          onChange={(e) => setPassword(e.target.value.trim())}
        />
      </div>
      <div>
        <button style={styles.btn} type="submit" disabled={userUpdating}>Login</button>
      </div>
    </form>
  );
}

const styles = {
  warning: {
    height: 50
  },
  btn: {
    padding: "5px 40px",
    color: "#D3D3D3",
    borderColor: "#D3D3D3",
  }
};
export default Login;
