export default function HomeScreen() {
  return (
    <div style={styles.container}>
      <iframe style={styles.video}
        title="PioSOLVER"
        src="https://www.youtube.com/embed/SPNVx3vnTRI?controls=0"
        frameBorder="0" allowFullScreen></iframe>
      <Products />
      <div style={styles.footer}></div>
    </div>
  );
}

function Products() {
  return (
    <div style={styles.products}>
      <ProductBox>
        <h4 style={styles.productBox.title}>Basic</h4>
        <span style={styles.productBox.headline}>
          Fully functional post-flop solver
        </span>
        <div style={styles.productBox.price}>
          $249
        </div>
        <br/>
        <a style={styles.productBox.button}
          href="https://piosolver.foxycart.com/cart?name=BASIC&price=249">
          Buy&nbsp;PioSOLVER&nbsp;Basic
        </a>
        <br/><br/>
        <ul style={styles.productBox.ul}>
          <li>Very fast GTO solver for Holdem</li>
          <li>Post-flop spots</li>
          <li>Set your starting ranges</li>
          <li>Set your stack sizes</li>
          <li>Set your bet sizes</li>
          <li>Set your desired accuracy</li>
          <li>Utilizes up to 6 cpu threads</li>
        </ul>
        <div style={styles.productBox.description}>
          PioSOLVER basic is fully functional post-flop solver. It uses maximum
          of 6 threads and solves trees of unlimited size. It can be used on one
          computer and offers very decent performance. It's perfect for
          occasional hand analysis as well as researching optimal play. The
          limitations shouldn't be noticeable for a semi-frequent user.
        </div>
      </ProductBox>
      <ProductBox>
        <h4 style={styles.productBox.title}>Pro</h4>
        <span style={styles.productBox.headline}>
          Our flagship product
        </span>
        <div style={styles.productBox.price}>
          $475
        </div>
        <br/>
        <a style={styles.productBox.button}
          href="https://piosolver.foxycart.com/cart?name=BASIC&price=475">
          Buy&nbsp;PioSOLVER&nbsp;Pro
        </a>
        <br/><br/>
        <ul style={styles.productBox.ul}>
          <li>Everything included in PioSOLVER Pro</li>
          <li>Utilizes up to 16 cpu threads</li>
        </ul>
        <div style={styles.productBox.description}>
          PioSOLVER pro is our flagship product. It uses up to 16 threads
          (logical CPUs) . It can make use of hexa cores or more powerful
          hardware. It is fully scriptable (feature not available in basic
          version) which makes it perfect tool for advanced and serious users.
          It can be used on 2 computers. Most new features are likely to make
          into this version with some also being available for basic users.
        </div>
      </ProductBox>
      <ProductBox>
        <h4 style={styles.productBox.title}>Edge</h4>
        <span style={styles.productBox.headline}>
          Our most powerful solver
        </span>
        <div style={styles.productBox.price}>
          $1099
        </div>
        <br/>
        <a style={styles.productBox.button}
          href="https://piosolver.foxycart.com/cart?name=PRO&price=1099">
          Buy&nbsp;PioSOLVER&nbsp;Edge
        </a>
        <br/><br/>
        <ul style={styles.productBox.ul}>
          <li>Everything included in PioSOLVER Pro</li>
          <li>Utilizes up to 64 cpu threads</li>
        </ul>
        <div style={styles.productBox.description}>
          PioSOLVER edge offers a preflop solver which requires very powerful
          hardware to run (at least 64GB of RAM and a fast modern CPU). It can
          be used on 2 computers at the same time and can be moved from computer
          without any limits. It's significantly faster on high end work station
          CPUs due to support for 64 cores.
        </div>
      </ProductBox>
      <div style={styles.free.container}>
        <h4 style={styles.free.title}>Get started for free</h4>
        PioSOLVER free is a turn and river solver. It's completely free, a bit
        slower than a pro version (it doesn't really matter for turn and river
        spots). It solves turns and rivers with arbitrary amount of bet sizes at
        every spot (up to 10). Range Explorer as well as aggregation tools are
        included.
        <div style={styles.free.downloadContainer}>
          <a style={styles.productBox.button} href="#/">
            Download&nbsp;PioSOLVER&nbsp;Free
          </a>
        </div>
      </div>
    </div>
  );
}

function ProductBox({ children }) {
  return (
    <div style={styles.productBox.container}>
      {children}
    </div>
  );
}

const styles = {
  container: {
    padding: 10,
    backgroundColor: "#D3D3D3",
  },
  video: {
    width: "100%",
    maxWidth: 840,
    display: "block",
    margin: "auto",
    border: "3px solid black",
  },
  free: {
    title: {
      margin: 5,
      marginLeft: 0,
    },
    downloadContainer: {
      textAlign: "right",
      margin: 20,
    },
    container: {
      textAlign: "left",
      maxWidth: 825,
      padding: 10,
      margin: 20,
      backgroundColor: "white",
      border: "3px solid black",
      flex: 1,
    }
  },
  products: {
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "center",
  },
  productBox: {
    container: {
      margin: 20,
      width: 250,
      backgroundColor: "white",
      border: "3px solid black",
    },
    title: {
      fontSize: 24,
      margin: 5,
      marginTop: 20,
    },
    headline: {
      fontSize: 14,
    },
    price: {
      fontSize: 24,
      margin: 10,
    },
    ul: {
      textAlign: "left",
      marginLeft: -10,
      marginRight: 15,
      minHeight: 170,
      listStyleType: "square",
    },
    button: {
      padding: 10,
      border: "2px solid black",
      backgroundColor: "#1dd1a1",
    },
    description: {
      padding: 10,
      fontSize: 14,
    }
  },
  footer: {
    minHeight: 25,
  }
};
