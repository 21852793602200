import { useNavigate } from "react-router-dom";

function SearchBar() {
  let navigate = useNavigate();
  function keyPress(e) {
    if (e.code !== "Enter") return;
    const q = e.target.value;
    navigate(`/tickets/${q}`);
    e.target.value = "";
  }
  return (
    <div style={styles.container}>
      <input autoFocus={true}
        onKeyPress={keyPress}
        placeholder="search by email address"
        style={styles.searchInput} />
    </div>
  );
}

const styles = {
  searchInput: {
    flex: 1,
    margin: "auto",
  },
  container: {
    padding: 5,
    maxWidth: 1500,
    display: "flex",
    color: "#D3D3D3",
    backgroundColor: "#888",
    margin: "auto",
    textAlign: "center",
  }
};

export default SearchBar;
