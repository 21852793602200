import { configureStore } from "@reduxjs/toolkit";
import { moderatorApi } from "../services/moderatorApi";

export const store = configureStore({
  reducer: {
    [moderatorApi.reducerPath]: moderatorApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(moderatorApi.middleware)
});
