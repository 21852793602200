import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const moderatorApi = createApi({
  reducerPath: "moderatorApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.piosolver.com/api/" }),
  tagTypes: ["Player", "Players", "RecentTickets", "PlayerTickets",
    "PlayerOrders"],
  endpoints: (builder) => ({
    getPlayerOrders: builder.query({
      query: (email) => {
        return {
          url: `orders/player?email=${email}`,
          credentials: "include",
        };
      },
      providesTags: () => [{ type: "PlayerOrders" }],
    }),
    getRecentTickets: builder.query({
      query: () => "tickets/recent",
      providesTags: () => [{ type: "RecentTickets" }],
    }),
    getPlayerTickets: builder.query({
      query: (email) => `tickets/player?email=${email}`,
      providesTags: () => [{ type: "PlayerTickets" }],
    }),
    getCurrentPlayer: builder.query({
      query: (email) => {
        return {
          url: 'player',
          credentials: "include",
        };
      },
      providesTags: (user) => {
        return [{ type: "Player", id: user }];
      },
    }),
    login: builder.mutation({
      query: (credentials) => {
        return {
          url: "login",
          method: "POST",
          body: credentials,
          credentials: "include",
        };
      },
      invalidatesTags: ["Player"],
    }),
    logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "POST",
        credentials: "include",
      }),
      invalidatesTags: ["Player"],
      credentials: "include",
    }),
  }),
});

export const {
  useGetPlayerOrdersQuery,
  useGetPlayerTicketsQuery,
  useGetRecentTicketsQuery,
  useGetCurrentPlayerQuery,
  useLoginMutation,
  useLogoutMutation } = moderatorApi;
