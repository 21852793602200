import { RequireUser } from "../auth";
import { useParams } from "react-router-dom";
import BackBar from "../BackBar";
import Spinner from "../spinner";
import {
  useGetPlayerTicketsQuery,
  useGetPlayerOrdersQuery,
} from "../../services/moderatorApi";

function OrdersTableRow({ order }) {
  const re = new RegExp(`${new Date().getFullYear()}-`);
  const date = order.created.split("T")[0].replace(re, "").replaceAll("-", "‑");
  return (
    <tr>
      <td>{order.order_id}</td>
      <td>{order.description}</td>
      <td>{date}</td>
    </tr>
  );
}

function OrdersTable({ orders }) {
  return (
    <div>
      <div style={styles.ordersHeader}>
        Orders
      </div>
      <table>
        <tbody>
          {orders.map((r) => <OrdersTableRow order={r} key={r.order_id} />)}
        </tbody>
      </table>
    </div>
  );
}

function Orders({ email }) {
  const { data, error, isLoading } = useGetPlayerOrdersQuery(email);
  const noResults = data && data.length === 0;

  return (
    <div>
      {error ? <div className="error">Error loading orders</div> : null}
      {isLoading ? <Spinner /> : null}
      {noResults ? <div style={styles.noResults}>No orders found</div> : null}
      { data && !noResults ? <OrdersTable orders={data} /> : null}
    </div>
  );
}
function EmailAttachments({ attachments }) {
  return (
    <div style={styles.attachments}>
      {attachments}
    </div>
  );
}

function EmailDetail({ email }) {
  const fromUs = email.from_email.match(/@piosolver.com$/);
  const re = new RegExp(`${new Date().getFullYear()}-`);
  const date = email.date.split("T")[0].replace(re, "").replaceAll("-", "‑");

  return (
    <div style={styles.email}>
      <div style={styles.emailHeader}>
        <div style={styles.emailSubject}>{email.subject}</div>
        <div style={styles.emailDate}>{date}</div>
      </div>
      {email.attachments ?
        <EmailAttachments attachments={email.attachments} /> : ""}
      <div style={fromUs ? styles.emailFromUsBody : styles.emailBody}>
        {email.body}
      </div>
    </div>
  );
}

function TicketDetailScreen() {
  const { email } = useParams();
  const { data, error, isLoading } = useGetPlayerTicketsQuery(email);
  const noResults = data && data.length === 0;

  return (
    <RequireUser>
      <div style={styles.container}>
        <BackBar to="/tickets" />
        <Orders email={email} />
        { isLoading ? <Spinner /> : null}
        {error ? <div className="error">Error loading data</div> : null}
        {noResults ? <div style={styles.noResults}>No results found</div> : null}
        <div style={styles.emailsContainer}>
          {data ? data.map((d) => <EmailDetail email={d} key={d.id} />) : null}
        </div>
      </div>
    </RequireUser>
  );
}

const styles = {
  container: {
    maxWidth: 1500,
    margin: "auto",
  },
  emailsContainer: {
    backgroundColor: "#dfe6e9",
  },
  ordersHeader: {
    display: "flex",
    padding: 5,
    color: "white",
    backgroundColor: "#444",
  },
  email: {
    padding: 5,
  },
  emailHeader: {
    display: "flex",
    padding: 5,
    color: "white",
    backgroundColor: "#444",
  },
  emailSubject: {
    flexGrow: 5,
  },
  emailDate: {
    fontSize: 12,
    flexGrow: 1,
    textAlign: "right",
  },
  emailFromUsBody: {
    whiteSpace: "pre-wrap",
    padding: 10,
    fontSize: 18,
    color: "#D3D3D3",
    backgroundColor: "#555"
  },
  emailBody: {
    whiteSpace: "pre-wrap",
    padding: 10,
    fontSize: 18,
    color: "#D3D3D3",
    backgroundColor: "#333"
  },
  attachments: {
    padding: 5,
  },
  noResults: {
    padding: "5px",
    textAlign: "center",
    color: "white",
    backgroundColor: "#fad390",
  }
};

export default TicketDetailScreen;
