import { Link } from "react-router-dom";
import { RequireUser } from "../auth";
import SearchBar from "../SearchBar";
import Spinner from "../spinner";
import { useGetRecentTicketsQuery } from "../../services/moderatorApi";

function TicketsTableRow({ data }) {
  const searchEmail = data.from_email.match(/@piosolver.com$/)
    ? data.to_email : data.from_email;
  return (
    <tr>
      <td>
        <Link to={"/tickets/" + searchEmail}>
          {searchEmail}
        </Link>
      </td>
      <td>
        <Link to={"/tickets/" + searchEmail}>
          {data.subject}
        </Link>
      </td>
    </tr>
  );
}

function TicketsTable({ rows }) {
  return (
    <table style={{maxWidth: 1500, margin: "auto"}}>
      <tbody>
        {rows ? rows.map((r) => <TicketsTableRow data={r} key={r.id}/>) : null}
      </tbody>
    </table>
  );
}

function TicketsScreen() {
  const { data, error, isLoading } = useGetRecentTicketsQuery();

  return (
    <RequireUser>
      <SearchBar />
        {error ? <div className="error">Error loading data</div> : null}
        {isLoading ? <Spinner /> : ""}
        <TicketsTable rows={data} />
    </RequireUser>
  );
}

export default TicketsScreen;
  