import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import TicketsScreen from "./components/tickets/TicketsScreen";
import TicketDetailScreen from "./components/tickets/TicketDetailScreen";
import HomeScreen from "./components/HomeScreen";
import Docs from "./components/Docs";
import LicensesScreen from "./components/LicensesScreen";

function NotFound() {
  return (
    <div style={styles.notFound}>
      <h1>404 Not found</h1>
    </div>
  );
}

function App() {
  return (
    <Fragment>
      <div className="side"></div>
      <div className="center">
        <NavBar />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/documentation" element={<Docs />} />
          <Route path="/licenses" element={<LicensesScreen />} />
          <Route path="/tickets" element={<TicketsScreen />} />
          <Route path="/tickets/:email" element={<TicketDetailScreen />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <div className="side"></div>
    </Fragment>
  );
}

const styles = {
  notFound: {
    padding: 20,
    backgroundColor: "#dfe6e9",
  }
};

export default App;
